.header-content {
  color: #fff;
  background: #5353e9;
  border-radius: 12px;
  justify-content: space-between;
  padding: 10px 10px 10px;
  display: flex;
}
.header-content-text p {
  color: #ffffff80;
  letter-spacing: 0;
  white-space: nowrap;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 15px;
  font-weight: 500;
  line-height: 25px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.header-content-text {
  position: relative;
}
.header-content-row {
  width: (1, 025px);
  height: (50px);
}

.header-content-text h6 {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0px;
  text-align: left;
  line-height: 25px;
  text-transform: capitalize;
}

.card-content.row {
  margin-top: 22px;
  margin-right: 0px;
}
.card-content-second {
  margin-top: 22px;
  margin-right: 0px;
}

.card-body-condition-inner.card {
  border: none;
  border-radius: 15px;
}
.card-body2-condition-inner.card {
  border: none;
  border-radius: 15px;
  width: 100%;
  height: 77%;
}
.medicines-body-content.card-body {
  overflow-x: clip;
  overflow-y: scroll;
  mix-blend-mode: hard-light;
}
.card-body3-condition-inner.card {
  border: none;
  border-radius: 15px;
  width: 100%;
}

.card-text-heading.card-title {
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0px;
  text-align: left;
}
.card-title {
  margin-bottom: 0px;
}

p.text-style {
  font-weight: 500;
  line-height: 20px;
  color: #1111119c;
}
p.text-style-diagnosis {
  width: Hug (282px);
  height: Hug (66px);
  gap: 16px;

  font-weight: 500;
  line-height: 25px;
  color: #00000061;
}
span.text-style {
  color: #1111119c;
  font-weight: 500;
  line-height: 25px;
  font-size: 16px;
}
.card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 16px 19px 17px !important;
  /* padding: 0; */
}

.card-container {
  width: Hug (341px);
  height: Hug (277px);
  padding: 15px, 0px, 0px, 0px;
  border-radius: 12px;
  gap: 10px;
}
.title-layout {
  width: Hug (339px);
  height: Hug (50px);
  padding: 0px, 221px, 0px, 20px;
  gap: 10px;
}

.title-heading {
  font-size: 22px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0px;
  text-align: left;
  color: #000000;
}
.element-case-history.test {
  color: #ef4d4d;
  letter-spacing: 0.25px;
  background: #fec8c8;
  border-radius: 7px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding: 8px 9px 8px 9px;
  font-family: Satoshi-Bold, Helvetica;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  display: inline-flex;
  margin-left: 9px;
  flex-wrap: nowrap;
  margin-top: 10px;
}
.element-case-history.test-2 {
  color: #5353e9;
  letter-spacing: 0.25px;
  background: #4d5def33;
  border-radius: 7px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding: 8px 9px 8px 9px;
  font-family: Satoshi-Bold, Helvetica;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  display: inline-flex;
  margin-left: 9px;
  flex-wrap: nowrap;
  margin-top: 10px;
}
.element-case-history.test-3 {
  color: #b1751b;
  background-color: #b1751b33;
  letter-spacing: 0.25px;
  border-radius: 7px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding: 8px 9px 8px 9px;
  font-family: Satoshi-Bold, Helvetica;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  display: inline-flex;
  margin-left: 9px;
  flex-wrap: nowrap;
  margin-top: 10px;
}
.element-case-history.test-4 {
  color: #1bb1b1;
  letter-spacing: 0.25px;
  background: #4dbeef33;
  border-radius: 7px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding: 8px 9px 8px 9px;
  font-family: Satoshi-Bold, Helvetica;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  display: inline-flex;
  margin-left: 9px;
  flex-wrap: nowrap;
  margin-top: 10px;
}
.element-case-history.test-5 {
  color: #39b11b;
  letter-spacing: 0.25px;
  background: #39b11b26;
  border-radius: 7px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding: 8px 9px 8px 9px;
  font-family: Satoshi-Bold, Helvetica;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  display: inline-flex;
  margin-left: 9px;
  flex-wrap: nowrap;
  margin-top: 10px;
}

.card-body-tests-recommended {
  padding: 0px;
  justify-content: space-between;
}

.test-recommended-section.col-4 {
  max-width: 32% !important;
}

.notes-content {
  width: Hug (345px);
  height: Hug (139px);
  padding: 16px;
  border-radius: 6px;
  gap: 10px;
  background: #e2e8f059;
}
.card-body2-condition-inner-initial.card {
  border: none;
  border-radius: 15px;
  width: 100%;
}

.element-case-history .text {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 114px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 89px;
  white-space: nowrap;
}

.element-case-history .top-nav {
  border-color: #e2e8f0;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  height: 74px;
  display: flex;
}

.element-case-history .overlap-group {
  flex: 1;
}

.element-case-history .frame-21 {
  align-items: flex-start;
  display: flex;
  left: 11px;
  position: relative;
  width: fit-content;
}

.element-case-history .topnav {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0px;
  position: relative;
  width: fit-content;
}

.element-case-history .menu-item-7 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: fit-content;
}

.element-case-history .frame-22 {
  height: 54px;
  min-width: 140px;
  position: relative;
}

.element-case-history .frame-23 {
  align-items: center;
  display: flex;
  gap: 19px;
  left: 25px;
  position: relative;
  top: 15px;
  width: fit-content;
}

.element-case-history .text-wrapper-6 {
  color: #888888;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-case-history .topnav-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0px;
  position: relative;
  width: fit-content;
}

.element-case-history .menu-item-8 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: fit-content;
}

.element-case-history .overlap-group-wrapper {
  height: 54px;
  min-width: 193px;
  position: relative;
}

.element-case-history .overlap-group-2 {
  height: 40px;
  left: 16px;
  position: relative;
  top: 7px;
  width: 152px;
}

.element-case-history .frame-24 {
  align-items: center;
  display: flex;
  gap: 19px;
  left: 9px;
  position: absolute;
  top: 8px;
  width: fit-content;
}

.element-case-history .text-wrapper-7 {
  color: #888888;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-case-history .topnav-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0px;
  position: relative;
  width: fit-content;
}

.element-case-history .menu-item-9 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: fit-content;
}

.element-case-history .frame-25 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px 25px;
  position: relative;
  width: fit-content;
}

.element-case-history .frame-26 {
  align-items: center;
  display: flex;
  gap: 19px;
  position: relative;
  width: fit-content;
}

.element-case-history .text-wrapper-8 {
  color: #888888;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-case-history .topnav-4 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0px;
  position: relative;
  width: fit-content;
}

.element-case-history .menu-item-10 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: fit-content;
}

.element-case-history .frame-27 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px 25px;
  position: relative;
  width: fit-content;
}

.element-case-history .frame-28 {
  align-items: center;
  display: flex;
  gap: 19px;
  position: relative;
  width: fit-content;
}

.element-case-history .text-wrapper-9 {
  color: #888888;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-case-history .text-wrapper-dashborad {
  color: #888888;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-case-history .div-row {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 20px;
  position: relative;
  width: fit-content;
}

.element-case-history .div-history-status {
  background-color: #5353e9;
  border-radius: 12px;
  height: 90px;
  min-width: 1104px;
  overflow: hidden;
  position: relative;
}

.element-case-history .overlap-group-3 {
  height: 251px;
  left: -50px;
  position: absolute;
  top: -106px;
  width: 1261px;
}

.element-case-history .shade-left {
  background-color: #ffffff66;
  border-radius: 85.5px;
  filter: blur(124px);
  height: 171px;
  left: 0;
  position: absolute;
  top: 0;
  width: 171px;
}

.element-case-history .shade-right {
  background-color: #ffffff66;
  border-radius: 85.5px;
  filter: blur(124px);
  height: 171px;
  left: 1090px;
  position: absolute;
  top: 80px;
  width: 171px;
}

.element-case-history .div-content {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  left: 50px;
  padding: 0px 32px 10px;
  position: absolute;
  top: 121px;
  width: fit-content;
}

.element-case-history .div-content-2 {
  align-items: flex-start;
  display: flex;
  gap: 140px;
  position: relative;
  width: fit-content;
}

.element-case-history .frame-29 {
  align-items: flex-start;
  display: flex;
  gap: 178px;
  position: relative;
  width: fit-content;
}

.element-case-history .group-3 {
  height: 50px;
  min-width: 73px;
  position: relative;
}

.element-case-history .text-wrapper-10 {
  color: #ffffff80;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 12px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: 25px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.element-case-history .text-wrapper-11 {
  color: #ffffff;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: 25px;
  position: absolute;
  top: 25px;
  white-space: nowrap;
}

.element-case-history .group-4 {
  height: 50px;
  min-width: 155px;
  position: relative;
}

.element-case-history .text-wrapper-12 {
  color: #ffffff80;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 12px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: 25px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.element-case-history .text-wrapper-13 {
  color: #ffffff;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: 25px;
  position: absolute;
  top: 25px;
  white-space: nowrap;
}

.element-case-history .group-5 {
  height: 50px;
  min-width: 141px;
  position: relative;
}

.element-case-history .text-wrapper-14 {
  color: #ffffff80;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 12px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: 25px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.element-case-history .text-wrapper-15 {
  color: #ffffff;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: 25px;
  position: absolute;
  top: 25px;
  white-space: nowrap;
}

.element-case-history .group-6 {
  height: 50px;
  margin-right: -4px;
  min-width: 153px;
  position: relative;
}

.element-case-history .text-wrapper-16 {
  color: #ffffff80;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 12px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: 25px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.element-case-history .text-wrapper-17 {
  color: #ffffff;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: 25px;
  position: absolute;
  top: 25px;
  white-space: nowrap;
}

.element-case-history .shade-center {
  background-color: #ffffff66;
  border-radius: 85.5px;
  filter: blur(124px);
  height: 171px;
  left: 450px;
  position: absolute;
  top: -157px;
  width: 171px;
}

.element-case-history .div-row-2 {
  align-items: flex-start;
  display: flex;
  gap: 20px;
  position: relative;
  width: fit-content;
}

.element-case-history .div-column {
  height: 761px;
  min-width: 701px;
  position: relative;
}

.element-case-history .div-row-3 {
  height: 464px;
  left: 0;
  position: absolute;
  top: 297px;
  width: 701px;
}

.div-medicines {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 12px;
  display: flex;
  gap: 10px;
  left: 360px;
  overflow: hidden;
  padding: 15px 0px 17px;
  position: absolute;
  top: 0;
  width: fit-content;
}

.div-medicines-inner {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  width: fit-content;
}

.div-heading {
  align-items: flex-start;
  display: flex;
  gap: 10px;
  padding: 0px 217px 0px 20px;
  position: relative;
  width: fit-content;
}

.heading {
  color: #000000;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 50px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.div-content-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: -1px 20px;
  position: relative;
  width: fit-content;
}

.div-medicine {
  align-items: flex-start;
  border: 1.5px solid;
  border-color: #e2e8f0;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  border: 1px solid #6d6f71;
  gap: 10px;
  overflow: hidden;
  padding: 9px 63px 0px 12px;
  position: relative;
  width: fit-content;
}

.div-content-4 {
  align-items: flex-start;
  display: flex;
  gap: 18px;
  position: relative;
  width: fit-content;
}

.icon {
  background-color: #85714442;
  border-radius: 16px;
  height: 54px;
  min-width: 54px;
  position: relative;
}
svg.medicines-icons {
  margin: 15px 0px 0px 15px;
  font-size: x-large;
  color: #b1530f;
}
.medicine {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
  width: fit-content;
}

.label {
  color: #000000;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: 154px;
}

.prescription {
  color: #00000066;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.div-content-wrapper {
  align-items: flex-start;
  border: 1.5px solid;
  border-color: #e2e8f0;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
  padding: 9px 63px 9px 12px;
  position: relative;
  width: fit-content;
}

.div-content-5 {
  align-items: flex-start;
  display: flex;
  gap: 18px;
  position: relative;
  width: fit-content;
}

.medcine-wrapper {
  background-color: #da53e926;
  border-radius: 16px;
  height: 54px;
  min-width: 54px;
  position: relative;
}

.medicine-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
  width: fit-content;
}

.label-2 {
  color: #000000;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: 154px;
}

.p {
  color: #00000066;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-case-history .div-medicine-2 {
  align-items: flex-start;
  border: 1.5px solid;
  border-color: #e2e8f0;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
  padding: 9px 63px 9px 12px;
  position: relative;
  width: fit-content;
}

.element-case-history .div-content-6 {
  align-items: flex-start;
  display: flex;
  gap: 18px;
  position: relative;
  width: fit-content;
}

.element-case-history .medcine-1-wrapper {
  background-color: #e9625326;
  border-radius: 16px;
  height: 54px;
  min-width: 54px;
  position: relative;
}

.element-case-history .medicine-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
  width: fit-content;
}

.element-case-history .label-3 {
  color: #000000;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: 154px;
}

.element-case-history .prescription-2 {
  color: #00000066;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-case-history .div-medicine-3 {
  align-items: flex-start;
  border: 1.5px solid;
  border-color: #e2e8f0;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
  padding: 9px 63px 9px 12px;
  position: relative;
  width: fit-content;
}

.element-case-history .div-content-7 {
  align-items: flex-start;
  display: flex;
  gap: 18px;
  position: relative;
  width: fit-content;
}

.element-case-history .medcine-instance-wrapper {
  background-color: #e9bc5326;
  border-radius: 16px;
  height: 54px;
  min-width: 54px;
  position: relative;
}

.element-case-history .medicine-4 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
  width: fit-content;
}

.element-case-history .label-4 {
  color: #000000;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: 154px;
}

.element-case-history .prescription-3 {
  color: #00000066;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-case-history .div-2 {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 12px;
  display: flex;
  gap: 10px;
  left: 0;
  overflow: hidden;
  padding: 15px 0px 17px;
  position: absolute;
  top: 0;
  width: fit-content;
}

.element-case-history .div-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  width: fit-content;
}

.element-case-history .heading-wrapper {
  height: 50px;
  min-width: 340px;
  position: relative;
}

.element-case-history .heading-2 {
  color: #000000;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 22px;
  font-weight: 700;
  height: 50px;
  left: 20px;
  letter-spacing: 0;
  line-height: 50px;
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.div-content-8 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: -1px 0px 43px;
  position: relative;
  width: fit-content;
}

.div-row-4 {
  align-items: flex-start;
  display: flex;
  gap: 68px;
  padding: 0px 56px 0px 20px;
  position: relative;
  width: fit-content;
}

.temp {
  align-items: center;
  display: flex;
  gap: 15px;
  position: relative;
  width: fit-content;
}

.icon-temp-wrapper {
  background-color: #5353e914;
  border-radius: 21px;
  height: 42px;
  min-width: 42px;
  position: relative;
}

.temp-value {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 3px;
  position: relative;
  width: fit-content;
}

.text-wrapper-18 {
  color: #11111180;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.text-wrapper-19 {
  color: #111111;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.value {
  align-items: center;
  display: flex;
  gap: 15px;
  position: relative;
  width: fit-content;
}

.spo-wrapper {
  background-color: #5353e914;
  border-radius: 21px;
  height: 42px;
  min-width: 42px;
  position: relative;
}

.spo-value {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 3px;
  position: relative;
  width: fit-content;
}

.text-wrapper-20 {
  color: #11111180;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.text-wrapper-21 {
  color: #111111;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.div-row-5 {
  align-items: flex-start;
  display: flex;
  gap: 38px;
  padding: 0px 41px 0px 20px;
  position: relative;
  width: fit-content;
}

.frame-30 {
  align-items: center;
  display: flex;
  gap: 15px;
  position: relative;
  width: fit-content;
}

.icon-height-wrapper {
  background-color: #5353e914;
  border-radius: 21px;
  height: 42px;
  min-width: 42px;
  position: relative;
}

.height-value {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 3px;
  position: relative;
  width: fit-content;
}

.text-wrapper-22 {
  color: #11111180;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.text-wrapper-23 {
  color: #111111;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame-31 {
  height: 51px;
  min-width: 111px;
  position: relative;
}

.icon-heart-beat-wrapper {
  background-color: #5353e914;
  border-radius: 21px;
  height: 42px;
  left: 0;
  position: absolute;
  top: 4px;
  width: 42px;
}

.diastolic-value {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 3px;
  left: 57px;
  position: absolute;
  top: 0;
  width: fit-content;
}

.text-wrapper-24 {
  color: #11111180;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.text-wrapper-25 {
  color: #111111;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.div-row-6 {
  align-items: flex-start;
  display: flex;
  gap: 37px;
  padding: 0px 49px 0px 20px;
  position: relative;
  width: fit-content;
}

.frame-32 {
  align-items: center;
  display: flex;
  gap: 15px;
  position: relative;
  width: fit-content;
}

.icon-BP-wrapper {
  background-color: #5353e914;
  border-radius: 21px;
  height: 42px;
  min-width: 42px;
  position: relative;
}

.bpsystolic-value {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 3px;
  position: relative;
  width: fit-content;
}

.text-wrapper-26 {
  color: #11111180;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.text-wrapper-27 {
  color: #111111;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame-33 {
  align-items: center;
  display: flex;
  gap: 15px;
  position: relative;
  width: fit-content;
}

.icon-weight-wrapper {
  background-color: #5353e914;
  border-radius: 21px;
  height: 42px;
  min-width: 42px;
  position: relative;
}

.weight-value {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 3px;
  position: relative;
  width: fit-content;
}

.text-wrapper-28 {
  color: #11111180;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.text-wrapper-29 {
  color: #111111;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.div-row-7 {
  align-items: flex-start;
  display: flex;
  gap: 73px;
  padding: 0px 61px 0px 20px;
  position: relative;
  width: fit-content;
}

.frame-34 {
  align-items: center;
  display: flex;
  gap: 15px;
  position: relative;
  width: fit-content;
}

.icon-relax-wrapper {
  background-color: #5353e914;
  border-radius: 21px;
  height: 42px;
  min-width: 42px;
  position: relative;
}

.RR-value {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 3px;
  position: relative;
  width: fit-content;
}

.text-wrapper-30 {
  color: #11111180;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.text-wrapper-31 {
  color: #111111;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  white-space: nowrap;
  width: 38px;
}

.frame-35 {
  align-items: center;
  display: flex;
  gap: 15px;
  position: relative;
  width: fit-content;
}

.icon-pulse-wrapper {
  background-color: #5353e914;
  border-radius: 21px;
  height: 42px;
  min-width: 42px;
  position: relative;
}

.pulse-value {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 3px;
  position: relative;
  width: fit-content;
}

.text-wrapper-32 {
  color: #11111180;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.text-wrapper-33 {
  color: #111111;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.div-row-8 {
  align-items: flex-start;
  display: flex;
  gap: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: fit-content;
}

.element-case-history .div-medicalcondition {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
  padding: 15px 0px 0px;
  position: relative;
  width: fit-content;
}

.element-case-history .div-medicalcondition-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  width: fit-content;
}

.element-case-history .div-heading-2 {
  align-items: flex-start;
  display: flex;
  gap: 10px;
  padding: 0px 132px 0px 20px;
  position: relative;
  width: fit-content;
}

.element-case-history .heading-3 {
  color: #000000;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 50px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-case-history .div-body {
  align-items: flex-start;
  display: flex;
  gap: 10px;
  padding: 9px 22px 43px 20px;
  position: relative;
  width: fit-content;
}

.element-case-history .description {
  color: #00000066;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  margin-top: -1px;
  position: relative;
  width: 298px;
}

.element-case-history .div-icdcode {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0px 10px 10px;
  position: relative;
  width: fit-content;
}

.element-case-history .div-icdcode-inner {
  background-color: #f5f7fa;
  border-radius: 12px;
  height: 50px;
  min-width: 320px;
  overflow: hidden;
  position: relative;
}

.element-case-history .label-5 {
  color: transparent;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 25px;
  left: 20px;
  letter-spacing: 0;
  line-height: 25px;
  position: absolute;
  top: 10px;
  white-space: nowrap;
}

.element-case-history .span {
  color: #00000066;
  font-weight: 500;
}

.element-case-history .text-wrapper-34 {
  color: #000000;
  font-family: "Satoshi-Bold", Helvetica;
  font-weight: 700;
}

.element-case-history .div-diagnosis {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
  padding: 15px 0px 0px;
  position: relative;
  width: fit-content;
}

.element-case-history .div-diagnosis-inner {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  width: fit-content;
}

.element-case-history .div-heading-3 {
  align-items: flex-start;
  display: flex;
  gap: 10px;
  padding: 0px 221px 0px 20px;
  position: relative;
  width: fit-content;
}

.element-case-history .heading-4 {
  color: #000000;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 50px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-case-history .types-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 25px 39px 61px 20px;
  position: relative;
  width: fit-content;
}

.element-case-history .types {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: fit-content;
}

.element-case-history .type {
  align-items: center;
  display: flex;
  gap: 15px;
  position: relative;
  width: fit-content;
}

.element-case-history .rectangle {
  background-color: #5353e9;
  height: 19px;
  min-width: 3px;
  position: relative;
}

.element-case-history .text-wrapper-35 {
  color: #11111166;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: 264px;
}

.element-case-history .type-2 {
  align-items: center;
  display: flex;
  gap: 15px;
  position: relative;
  width: fit-content;
}

.element-case-history .rectangle-2 {
  background-color: #5353e9;
  height: 19px;
  min-width: 3px;
  position: relative;
}

.element-case-history .text-wrapper-36 {
  color: #11111166;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: 264px;
}

.element-case-history .div-icdcode-inner-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0px 10px 10px;
  position: relative;
  width: fit-content;
}

.element-case-history .label-wrapper {
  background-color: #f5f7fa;
  border-radius: 12px;
  height: 50px;
  min-width: 320px;
  overflow: hidden;
  position: relative;
}

.element-case-history .label-6 {
  color: transparent;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 25px;
  left: 20px;
  letter-spacing: 0;
  line-height: 25px;
  position: absolute;
  top: 10px;
  white-space: nowrap;
}

.element-case-history .text-wrapper-37 {
  color: #00000066;
  font-weight: 500;
}

.element-case-history .text-wrapper-38 {
  color: #000000;
  font-family: "Satoshi-Bold", Helvetica;
  font-weight: 700;
}

.element-case-history .div-column-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 761px;
  position: relative;
  width: fit-content;
}

.element-case-history .div-testsrecommended {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
  padding: 15px 0px 0px;
  position: relative;
  width: fit-content;
}

.element-case-history .div-testsrecommended-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  width: fit-content;
}

.element-case-history .div-heading-4 {
  align-items: flex-start;
  display: flex;
  gap: 10px;
  padding: 0px 147px 0px 20px;
  position: relative;
  width: fit-content;
}

.element-case-history .heading-5 {
  color: #000000;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 50px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-case-history .div-tests-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px 57px 17px 20px;
  position: relative;
  width: fit-content;
}

.element-case-history .div-tests {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: fit-content;
}

.element-case-history .div-row-9 {
  height: 37px;
  min-width: 303px;
  position: relative;
}

.element-case-history .div-icdcode-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0px 10px 10px;
  position: relative;
  width: fit-content;
}

.element-case-history .p-wrapper {
  background-color: #f5f7fa;
  border-radius: 12px;
  height: 50px;
  min-width: 360px;
  overflow: hidden;
  position: relative;
}

.element-case-history .label-7 {
  color: transparent;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 25px;
  left: 20px;
  letter-spacing: 0;
  line-height: 25px;
  position: absolute;
  top: 10px;
  white-space: nowrap;
  width: 280px;
}

.element-case-history .text-wrapper-44 {
  color: #00000066;
  font-weight: 500;
}

.element-case-history .text-wrapper-45 {
  color: #000000;
  font-weight: 500;
}

.element-case-history .text-wrapper-46 {
  color: #000000;
  font-family: "Satoshi-Bold", Helvetica;
  font-weight: 700;
}

.element-case-history .div-notes {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
  padding: 15px 0px 0px;
  position: relative;
  width: fit-content;
}

.element-case-history .div-notes-inner {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  width: fit-content;
}

.element-case-history .div-heading-5 {
  align-items: flex-start;
  display: flex;
  gap: 10px;
  padding: 0px 300px 0px 20px;
  position: relative;
  width: fit-content;
}

.element-case-history .heading-6 {
  color: #000000;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 50px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-case-history .div-note-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px 18px 17px 17px;
  position: relative;
  width: fit-content;
}

.element-case-history .div-note {
  align-items: flex-start;
  background-color: #e2e8f059;
  border-radius: 6px;
  display: flex;
  gap: 10px;
  overflow: hidden;
  padding: 16px;
  position: relative;
  width: fit-content;
}

.element-case-history .description-2 {
  color: #00000066;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  height: 107px;
  letter-spacing: 0;
  line-height: 25px;
  margin-top: -1px;
  position: relative;
  width: 313px;
}

.element-case-history .div-reports {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
  padding: 15px 0px 0px;
  position: relative;
  width: fit-content;
}

.element-case-history .div-reports-inner {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  width: fit-content;
}

.element-case-history .div-heading-6 {
  align-items: flex-start;
  display: flex;
  gap: 10px;
  padding: 0px 280px 0px 20px;
  position: relative;
  width: fit-content;
}

.element-case-history .heading-7 {
  color: #000000;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 50px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-case-history .div-content-9 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 15px 23px 17px 20px;
  position: relative;
  width: fit-content;
}

.element-case-history .div-row-11 {
  align-items: center;
  border-radius: 10px;
  display: flex;
  gap: 197px;
  overflow: hidden;
  padding: 9px 15px;
  position: relative;
  width: fit-content;
}

.element-case-history .label-8 {
  color: #5353e9;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-case-history .div-row-12 {
  align-items: center;
  border-radius: 10px;
  display: flex;
  gap: 206px;
  overflow: hidden;
  padding: 9px 15px;
  position: relative;
  width: fit-content;
}

.element-case-history .label-9 {
  color: #00000080;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-case-history .div-row-13 {
  align-items: center;
  border-radius: 10px;
  display: flex;
  gap: 249px;
  overflow: hidden;
  padding: 9px 15px;
  position: relative;
  width: fit-content;
}

.element-case-history .label-10 {
  color: #00000080;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-case-history .div-topbar {
  flex: 1;
  justify-content: space-between;
  display: flex;
  vertical-align: middle;
  align-items: center;
}

.element-case-history .div-search {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  position: relative;
  width: fit-content;
}

.element-case-history .div-search-inner {
  align-items: center;
  display: flex;
  gap: 20px;
  position: relative;
  width: fit-content;
}

.element-case-history .icon-2 {
  height: 20px;
  min-width: 20px;
  position: relative;
}

.element-case-history .text-wrapper-47 {
  color: #636a81;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: 639px;
}

.element-case-history .div-tools-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 17px 36px 17px 0px;
  position: relative;
  width: fit-content;
}

.element-case-history .div-tools {
  align-items: center;
  display: flex;
  gap: 32px;
  position: relative;
  width: fit-content;
}

.element-case-history .div-actions {
  height: 34px;
  min-width: 83px;
  position: relative;
}

.element-case-history .icon-3 {
  background-color: #e2e3f0;
  border: 0.5px solid;
  border-color: #e2e8f0;
  border-radius: 17px;
  height: 34px;
  left: 49px;
  position: absolute;
  top: 0;
  width: 34px;
}

.element-case-history .overlap-group-4 {
  height: 27px;
  left: 8px;
  position: relative;
  width: 25px;
}

.element-case-history .chat-alt {
  height: 18px;
  left: 0;
  position: absolute;
  top: 9px;
  width: 18px;
}

.element-case-history .ellipse-2 {
  background-color: #dc3545;
  border: 2px solid;
  border-color: #ffffff;
  border-radius: 5px;
  height: 10px;
  left: 15px;
  position: absolute;
  top: 0;
  width: 10px;
}

.element-case-history .alarm-wrapper {
  background-color: #e2e3f0;
  border: 0.5px solid;
  border-color: #e2e8f0;
  border-radius: 17px;
  height: 34px;
  left: 0;
  position: absolute;
  top: 0;
  width: 34px;
}

.element-case-history .alarm {
  height: 18px;
  left: 8px;
  position: absolute;
  top: 8px;
  width: 18px;
}

.element-case-history .div-profile {
  height: 46px;
  min-width: 183px;
  position: relative;
}

.element-case-history .profile {
  height: 46px;
  left: 107px;
  position: absolute;
  top: 0;
  width: 76px;
}

.element-case-history .group-7 {
  background-image: url(../../../static/img/ellipse-2824.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 46px;
  left: 0;
  position: absolute;
  top: 0;
  width: 46px;
}

.element-case-history .chevron-down {
  height: 20px;
  left: 56px;
  position: absolute;
  top: 13px;
  width: 20px;
}

.element-case-history .name {
  height: 35px;
  left: 0;
  position: absolute;
  top: 6px;
  width: 92px;
}

.element-case-history .text-wrapper-48 {
  color: #212b36;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  text-align: right;
  top: -1px;
  white-space: nowrap;
}

.element-case-history .text-wrapper-49 {
  color: #637381;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 12px;
  font-weight: 500;
  left: 26px;
  letter-spacing: 0;
  line-height: 14px;
  position: absolute;
  text-align: right;
  top: 20px;
  white-space: nowrap;
}

/* ========================================================================= */
.case-history-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 20px;
}

.inner-card {
  background: #fff;
  border-radius: 12px;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
  display: flex;
  padding: 15px 20px;
  box-shadow: 0px 0px 8px -5px rgba(0, 0, 0, 0.5);
}
.small-card {
  min-height: 240px;
}
.small-card-third {
  min-height: 210px;
}
.inner-card h3 {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  font-family: "Satoshi-Bold", Helvetica;
  margin-bottom: 20px !important;
}
.inner-card .description {
  color: #00000094;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
}
.diagnosis-list {
  padding-top: 20px;
}
.inner-card .diagnosis-list li {
  color: #1111119c;
  margin-bottom: 10px;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
}
.inner-card .diagnosis-list li:before {
  content: "";
  border-left: 4px solid #5353e9;
  margin-right: 10px;
}
.medical-condition-footer {
  background: #f5f7fa;
  border-radius: 12px;
  align-items: center;
  gap: 5px;
  padding: 12px 20px;
  display: flex;
}
.medical-condition-footer span {
  letter-spacing: 0;
  text-align: left;
  color: #1111119c;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  text-transform: capitalize;
}
.medical-condition-footer .medical-code {
  font-weight: 700;
  color: #000000;
}
.examination-findings-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}
.examination-inner {
  flex-basis: 48%;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  /* padding: 0px 5px; */
  display: flex;
  margin-bottom: 25px;
}
.examination-icon {
  background: #5353e914;
  border-radius: 100%;
  width: 55px;
  height: 55px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.examination-icon img {
  height: 30px;
  width: 30px;
}

.examination-content p {
  color: #1111119c;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.examination-content h5 {
  color: #111;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
.test-recommended {
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
}
.medical-complaint {
  color: #b1751b;
  background: #f3eadd;
  border-radius: 7px;
  padding: 6px 10px;
  display: inline-block;
  margin: 6px !important;
  font-weight: 700;
}
.test-names {
  color: #1bb1b1;
  background: #4dbeef33;
  padding: 6px 10px;
  border-radius: 7px;
}
.test-names p {
  font-size: 15px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0.25px;
  text-align: left;
}
.examination-notes {
  background: #e2e8f059;
  border-radius: 6px;
  padding: 16px;
  margin-top: 15px;
}
.examination-notes p {
  color: #1111119c;
  letter-spacing: 0;
  margin-top: -1px;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  position: relative;
}
.medicines-wrapper {
  max-height: 340px;
  margin-top: 20px;
  overflow-y: scroll;
}
.medicines-inner {
  border: 1.5px solid #e2e8f0;
  border-radius: 12px;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 20px;
  padding: 10px 12px;
  display: flex;
  align-items: center;
}
.medicines-icon {
  width: 54px;
  height: 54px;
  flex-shrink: 0;
  border-radius: 16px;
  background: rgba(218, 83, 233, 0.15);
  position: relative;
}
.medicines-icon img {
  width: 36px;
  height: 36px;
  position: absolute;
  top: 9px;
  left: 9px;
}
.medicines-content h5 {
  color: #000;
}
.medicines-content p {
  color: rgba(0, 0, 0, 0.4);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
}
.div-history-status {
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: end;
}
.case-history-header {
  flex: 1;
}
.div-history-status .shade-left {
  background-color: #ffffff66;
  border-radius: 85.5px;
  filter: blur(124px);
  height: 171px;
  left: 0;
  position: absolute;
  top: 0;
  width: 171px;
}
.div-history-status .shade-right {
  background-color: #ffffff66;
  border-radius: 85.5px;
  filter: blur(124px);
  height: 171px;
  left: 1090px;
  position: absolute;
  top: 80px;
  width: 171px;
}
.div-history-status .shade-center {
  background-color: #ffffff66;
  border-radius: 85.5px;
  filter: blur(124px);
  height: 171px;
  left: 450px;
  position: absolute;
  top: -157px;
  width: 171px;
}
.medical-box-color {
  background-color: #ffccb354;
  position: relative;
}
.diagnosis-box-color h3 {
  color: green;
}
.reports-list {
  margin-top: 15px;
}
.reports-list li {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  margin-bottom: 8px;
}
.reports-list li a {
  color: #5353e9;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  display: flex;
  margin-bottom: 8px;
}
.reports-list li a img {
  width: 25px;
  height: auto;
}
.no-data {
  text-transform: capitalize;
}
.filer-by-date label {
  display: block;
  margin-bottom: 0;
}
.documentIconReport {
  /* border: #5353e9 2px solid; */
  border-radius: 10px;
  position: relative;
  cursor: pointer;
}

.otherDocImg img {
  width: 100%;
  height: auto;
}

